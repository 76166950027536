.my-order-container {
    margin-top: 102px;
    min-height: 100vh;
    background-image: url(../../imgs/global/Group\ 1.png);
    background-position: bottom center;
    background-size: contain;
    padding-bottom: 170px;
    background-color: #000;
}
.my-order-container .head-order {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: black;
}
.my-order-container .head-order .head-order-btn {
    cursor: pointer;
    text-align: center;
    width: 50%;
    height: calc(25px + 32px) !important;
    padding: 16px 0;
    background: rgba(117, 117, 117, 0.70);
    box-shadow: -5px 4px 4px 0px rgba(0, 0, 0, 0.25) inset, 4px -2px 4px 0px rgba(0, 0, 0, 0.25) inset;
    color: white;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.49%;
}
.my-order-container .head-order .head-order-btn a{
    color: white;
    width: 100%;
    height: 100%;
    display: block;
}
.my-order-container .head-order .head-order-btn.active-order {
    background: var(--vf, linear-gradient(180deg, #CDA34F 0%, #A77C27 100%));
}
.my-order-container .submit-order {
    cursor: pointer;
    position: fixed;
    z-index: 100;
    bottom: 113px;
    left: 50%;
    transform: translateX(-50%);
    width: 345px;
    height: 69px;
    border-radius: 80px;
    background: #CDA34F;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.49%; 
    transition-duration: .8s;
}
.my-order-container .submit-order.down {
    bottom: -113px;
    transition-duration: .8s;
}
.my-order-container .submit-order:hover , .my-order-container .submit-order:focus {
    background-color: #A77C27;
}
.page .total {
    position: fixed;
    z-index: 102;
    color: white;
    top: 30px;
    padding: 4px 20px 4px 25px;
    border-radius: 0px 25.5px 25.5px 0px;
    background: #CDA34F;
}
.page .total span {
    display: block;
}
.page .total span {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.page .total span:first-child {
    color: rgba(0, 0, 0, 0.50);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.note-writer .writer #final-note {
    background-color: white;
    border: 1px solid #CDA34F !important;
    outline: none;
    border-radius: 8px;
    padding: 9px 14px;
    height: 130px;
    width: 280px;
    resize: none;
}
.submit-order-overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    background-color: #0000001a;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.submitting-order {
    position: relative;
    background-color: white;
    width: 345px;
    height: 341px;
    border-radius: 40px;
    padding: 32px 30px 10px;
}
.submit-order-overlay .submitting-order h5 {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.49%;
    background: var(--vf, linear-gradient(180deg, #CDA34F 0%, #A77C27 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.submit-order-overlay .submitting-order p {
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%;
}
.submit-order-overlay .submitting-order .submit-value {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 14px 25px 16px 64px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.50);
}
.submit-order-overlay .submitting-order .submit-value {
    cursor: pointer;
    margin-top: 10px;
}
.submit-order-overlay .submitting-order .submit-value#Together {
    margin-top: 24px;
}
.submit-order-overlay .submitting-order .submit-value svg {
    position: absolute;
    left: 26px;
    bottom: 50%;
    transform: translateY(50%);
}
.submit-order-overlay .submitting-order .btns-cont {
    margin-top: 47px;
}
.submit-order-overlay .submitting-order .btns-cont .cancel {
    background-color: transparent;
    border: none;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%;
    padding: 10px 15px;
}
.submit-order-overlay .submitting-order .btns-cont .submit {
    border-radius: 80px;
    background: #CDA34F;
    color: white;
    border: none;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 128%;
    padding: 10px 15px;
}
.submit-order-overlay .submitting-order .submit-value.active-sub {
    background: var(--vf, linear-gradient(180deg, #CDA34F 0%, #A77C27 100%));
    color: white;
    border: 1px solid #a77c2732;
}
.submit-order-overlay .submitting-order .submit-value.active-sub .normal-icon-sub {
    opacity: 0 !important;
}
.submit-order-overlay .submitting-order .submit-value.active-sub .active-icon-sub {
    opacity: 100 !important;
}
@media(min-width : 767px) {
    .my-order-container .head-order .head-order-btn {
        height: 75px !important;
        color: #FFF;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 140.49%;
        padding: 22px 0;
    }
    .my-order-container .submit-order {
        bottom: 210px;
        width: 452px;
        height: 77px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
    }
    .submitting-order {
        transform: scale(1.5);
        width: 420px;
    }
}