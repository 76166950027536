.page .trnding-section {
    background-color: black;
    margin-top: 102px;
    min-height: 100vh;
    padding-bottom: 120px;
    background-image: url(../../imgs/global/Group\ 1.png);
    background-size: contain;
}
.bad-network:hover {
    background-color: #b08839 !important;
    transition-duration: .4s;
}
@media(min-width : 767px) {
    .page .trnding-section {
    margin-top: 180px !important;
    }
}