.card-menu .quantity {
    position: absolute;
    right: 30px;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-menu .quantity span {
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translateX(50%) translateY(50%);
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.49%;
    z-index: 2;
}
@media(min-width : 767px) {
    .table-orders {
        height: 220px;
        margin-bottom: 50px !important;
    }
    .card-menu .card-body {
        height: 220px;
        overflow: hidden;
        position: relative;
        z-index: 2;
    }
    .card-menu .quantity {
        transform: scale(1.6);
        bottom: 40px;
        right: 40px;
    }
    .card-menu {
        margin-bottom: 50px !important;
    }
    .table-orders h4 {
        font-size: 22px !important;
    }
    .card-body img {
        height: fit-content !important;
        transform: translateY(-30%);
        z-index: -1;
    }
    .card-menu .card-body::before {
        z-index: 1  ;
    }
    .card-text {
        z-index: 2;
    }
    .quantity {
        z-index: 2;
    }
    .price {
        z-index: 2;
    }
    .clicker {
        z-index: 10;
    }
    .add-note {
        z-index: 2;
    }
    .amount {
        z-index: 2;
    }
}