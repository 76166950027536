.pack {
    margin-bottom: 25px;
}
.pack .card-body::before {
    background: linear-gradient(268deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
}
.pack .card-body .price {
    top: auto ;
    bottom: 0;
    width: 126.765px;
    height: 38.609px;
}
.pack .card-body .price .old-price {
    color: #4C4C47;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-right: 11.5px;
    display: flex;
    align-items: center;
    /* padding: 0 3px; */
}
.oldPrice {
    text-decoration: line-through;
}
.old-price .curr-name-label {
    font-size: 6px !important;
}
.pack .card-body .card-text {
    bottom: auto;
    top: 12.5px;
}
.pack .card-body .card-text ul {
    margin-left: 19px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 80px;
    padding-left: 0;
    width: 200px;
}
.pack .card-body .card-text .det {
    color: #FFF;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}
.pack .hidden-pack {
    position: absolute;
    z-index: -1;
    top: 0;
}