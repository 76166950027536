.goodbye-container {
    width: 100vw;
    height: 100vh;
    background-image: url("../../imgs/goodbye/goodbyeBackGround.svg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.goodbye-container .goodbye-img {
    width: 219px;
    height: 68px;
    margin-top: 50%;
    transform: translateY(-50%);
}
.goodbye-container .goodbye-text {
    color: white;
    text-align: center;
    width: 90%;
    margin-top: 50px;
    min-height: 250px;
}
.goodbye-container .goodbye-text h3 {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 238%;
    margin: 0;
}
.goodbye-container .goodbye-text h4 {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 207%;
    margin: 5px 0;
}
.goodbye-container .goodbye-text .p1 {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 242%;
}
.goodbye-container .goodbye-text .p2 {
    color: rgba(255, 255, 255, 0.70);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 220%;
}
.goodbye-container span {
    width: 90%;
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 80px;
    background: #CDA34F;
    color: white;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.49%;
    margin-top: 85px;
    transition-duration: .3s;
    cursor: pointer;
}
.goodbye-container span:hover {
    background: #c3973f;
    transform: scale(0.95);
    transition-duration: .3s;
}