.card-menu {
    width: 100%;
    height: 170px;
    margin: 0 0 13px ;
    position: relative;
    z-index: 1;
}
.card-body {
    width: 100%;
    height: 170px;
    position: relative;
}
.card-menu .card-body img {
    width: 100%;
    height: 100%;
    z-index: 1;
}
.card-menu .card-body::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
}
.card-menu .card-overlay-menu {
    background-color: transparent;
    position: absolute;
    width: 70%;
    height: 100%;
    bottom: 0;
    left: 0%;
    cursor: pointer;
    z-index: 100;
}
.card-menu .card-body .card-text {
    position: absolute;
    bottom: 13.7px;
    left: 23px;
    color: white;
    width: 300px;
}
.card-menu .card-body .card-text h4 {
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.49%; 
}
.card-menu .card-body .card-text p {
    color: #FFF;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    width: 80%;
}
.card-menu .card-body .price {
    z-index: 2;
    position: absolute;
    top: 13.7px;
    left: 0;
    color: white;
    border-radius: 0px 25.5px 25.5px 0px;
    background: #CDA34F;
    width: 79.942px;
    height: 50.249px;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-note {
    cursor: pointer;
    width: 44px;
    height: 45px;
    border-radius: 25.5px 25.5px 0px 25.5px;
    background: #FFF;
    position: absolute;
    right: 26.2px;
    top: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.add-note span {
    color: #000;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.49%;
    margin-top: 1px;
}
.amount {
    position: absolute;
    right: 17px;
    bottom: 16px;
}
.clicker {
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    right: 0px;
    z-index: 0;
}
.amount .inc-dec span {
    display: block;
    width: 37px;
    text-align: center;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.49%;
}
.amount .inc-dec {
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
}
.amount .inc-dec .inc svg, .amount .inc-dec .dec svg{
    cursor: pointer;
    width: 31px;
    height: 31px;
}
.op-0{
    opacity: 0;
}
.op-100 {
    opacity: 100;
}
.z-in-99 {
    z-index: 99 !important;
}
.z-in-1 {
    z-index: -1 !important;
}
.note-writer {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    background-color: #0000001a;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.note-writer .writer {
    position: relative;
    background-color: white;
    width: 345px;
    height: 341px;
    border-radius: 40px;
    padding: 32px 30px 10px;
}
.note-writer .writer h5 {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.49%;
    background: var(--vf, linear-gradient(180deg, #CDA34F 0%, #A77C27 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.note-writer .writer p {
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%;
}
.note-writer .writer .textareaNote {
    background-color: white;
    border:1px solid #CDA34F !important;
    outline: none;
    border-radius: 8px;
    padding: 9px 14px;
    height: 130px;
    width: 280px;
    resize: none;
}
.note-writer .writer .note:active ,.note-writer .writer .note:focus {
    /* border: 0; */
    outline: none;
}
.note-writer .writer .btns-cont {
    margin-top: 20px;
}
.note-writer .writer .btns-cont .cancel {
    background-color: transparent;
    border: none;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%;
    padding: 10px 15px;
}
.note-writer .writer .btns-cont .submit {
    border-radius: 80px;
    background: #CDA34F;
    color: white;
    border: none;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 128%;
    padding: 10px 15px;
}
.exit-note {
    padding: 10px;
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 25px;
    top:20px;
}
.exit-note span {
    position: absolute;
    display: block;
    background-color: #CDA34F;
    width: 20px;
    height: 3px;
    border-radius: 3px;
}
.exit-note span.sp1 {
    transform: rotate(-45deg);
}
.exit-note span.sp2 {
    transform: rotate(45deg);
}
.hidden-infos {
    position: absolute;
    z-index: -1;
    top: 0;
    opacity: 0;
}
.hidden-infos span {
}
.curr-name-label {
    font-size: 8px;
    margin-bottom: -10px;
    margin-left: 2px;
    font-weight: 600 !important;
}
.curr-name-label-total {
    font-size: 8px;
    display: block;
    margin-bottom: -10px;
    margin-left: 2px;
    font-weight: 700 !important;
    color: rgba(0, 0, 0, 0.781) !important;
}
@media(min-width : 767px) {
    .card-menu {
        width: 100%;
        height: 190px;
    }
    .card-body {
        height: 190px;
    }
    .clicker {
        transform: scale(1.4);
        right: 10px;
    }
    .card-menu .price {
        transform: scale(1.5);
        top: 35.7px !important;
        left: 20px !important;
    }
    .inc-dec {
        transform: scale(1.5);
        margin: 0 30px 10px 0;
    }
    .add-note {
        transform: scale(1.5);
        right: 50.2px;
        top: 30px;
    }
    .writer {
        transform: scale(1.5);
        width: 400px !important;
    }
}