.page {
    height: 100vh;
    width: 100vw;
}
.loader {
    background-image: url('../../imgs/loader/Capa\ 1.svg');
    background-size: 130vw 120vh;
    overflow: hidden;
    max-height: 100vh;
    height: 100%;
    background-color: black;
    width: 100%;
    position: relative;
}
@media(min-width :767px) {
    .load-p {
    margin-top: 0 !important;
    }
    .loader {
        background-image: url('./Capa\ 1\ \(1\).svg');
        background-size: 100vw 100vh;
    }
}
.clip {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.intro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.9);
    animation-name: intro;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}
@keyframes intro {
    0% {
    transform: translateX(-50%) translateY(-50%) scale(0.85);
    }
    50% {
    transform: translateX(-50%) translateY(-50%) scale(.95);
    }
    100% {
    transform: translateX(-50%) translateY(-50%) scale(0.85);
    }
}
.foot {
    position: fixed;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
}
@media(min-width :767px) {
    .foot {
        width: 300px;
    }
    .intro {
        transform: translateX(-50%) translateY(-50%) scale(1.05);
        animation-name: intro2;
        animation-duration: 2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
    }
}
@keyframes intro2 {
    0% {
    transform: translateX(-50%) translateY(-50%) scale(1.05);
    }
    50% {
    transform: translateX(-50%) translateY(-50%) scale(1.3);
    }
    100% {
    transform: translateX(-50%) translateY(-50%) scale(1.05);
    }
}