.img_item_page {
    z-index: 102;
    position: fixed;
    left: 0;
    top: 0;
    /* height: 102px; */
    width: 100%;
}
.arrow-back {
    position: fixed;
    top: 43px;
    left: 35px;
    z-index: 102;
    cursor: pointer;
}
.item_page {
    min-height: calc(100vh - 102px);
    min-width: 100vw;
    background-color: black;
    margin-top: 102px;
    position: relative;
}
.item_page img {
    width: 100%;
    height: 266px;
    position: relative; 
    z-index: 0;   
}
.overlay_image_item {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 1.29%, #000 99.65%);
    width: 100%;
    height: 300px;
    position: absolute;
    top: 50px;
}
.content_item {
    color: white;
    position: relative;
}
.price_item {
    background-color: #CDA34F;
    width: 101px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 0px 40px 40px 0;
    margin-top: -20px;
    position: relative;
}
.content_item .header_item {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.49%; /* 28.098px */
    width: 90%;
    margin: 0 auto;
    margin-top: 25px;
}
.content_item .desc_item {
    width: 90%;
    margin: 0 auto;
    margin-top: 15px;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
}
.amount2 {
    position: fixed;
    bottom: 80px;
    right: 20px;
}
.package-photo {
    width: 393px;
    height: 266px;
}
.item_page .carousel-inner {
    min-height: 300px !important;
}
.pack_page .price_item {
    margin-top: -65px;
    min-width: 160px;
}
.pack_page .old-price {
    color: #4C4C47;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: strikethrough;
    margin-right: 10px;
    text-decoration-line: line-through;
}
.pack_page h4 {
    margin-top: 40px !important;
}
.pack_page .details {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    margin: 15px 0 0 30px;
}
.pack_page .details .det {
    margin-bottom: 5px;
}
@media(min-width:767px) {
    .pack_page .header_item {
        margin-top: 80px !important;
        font-size: 30px !important;
    }
    .pack_page .details {
        font-size: 24px;
        width: 80% !important;
        margin: 20px auto;
    }
    .pack_page .details .det {
        margin-bottom: 15px;
    }
    .pack_page .package-photo {
        height: 553px;
    }
    .pack_page .carousel-inner {
        height: 600px;
    }
    .pack_page .carousel-inner .overlay_image_item {
        top: 0px;
    }
    .carousel-item::after {
        display: none;
    }
    .arrow-back {
        transform: scale(1.6);
        top: 75px;
        left: 40px;
    }
    .carousel-indicators button {
        width: 15px !important;
        height: 15px !important;
    }
}