.search-icon.pack .normal-icon, .search-icon.pack .active-icon {
    position: absolute;
    top: 17px;
    right: 36px;
}
.packages .search {
    padding-bottom: 50px;
}
.menu-container.packages h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.49%;
    width: 100%;
    margin: 0;
    margin-top: 102px;
    padding: 5px 0 0px 24px;
    background-color: black;
}
.pack-cards .card-menu {
    height: 160px;
}
.arrow-back-home {
    cursor: pointer;
    position: absolute;
    top: 37px;
    left: 27px;
    padding: 5px;
} 
@media(min-width : 767px) {
    .page {
        margin-top: 180px;
    }
    .search-icon.pack .normal-icon{
        top: 30px;
        right: 124px;
    }
    .pack-cards .card-text {
        transform: scale(1.3);
    }
    .pack .card-body .card-text {
        bottom: auto;
        left: 79px;
    }
    .details {
    }
    .pack-cards .card-menu {
        height: 250px;
    }
    .pack .card-body {
        height: 250px;
    }
    .pack-cards .pack .card-body .price {
        top: 175.7px !important;
        left: 20px !important;
    }
}