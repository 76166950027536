.please-wait {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    /* background-color: black; */
    position: relative;
    background-image: url(../../imgs/goodbye/goodbyeBackGround.svg);
    background-size: cover;
}
.please-wait img {
    /* width: 100%; */
    position: absolute;
    /* top: -150px; */
    /* left: 0; */
    /* z-index: 0; */
}
.please-wait p {
    z-index: 1;
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.15%, #000 100%); */
    position: absolute;
    top: 280px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 300px;
    padding: 0 30px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 147.99%;
}
.please-wait .looping-circles{
    z-index: 2;
    position: absolute;
    width: 84px;
    height: 84px;
    top: 370px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    animation-name: animate-loop;
    animation-duration: 1s;
    animation-timing-function:cubic-bezier(0.23, 1, 0.320, 1);
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}
.please-wait .looping-circles .sp{
    display: block;
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #CDA34F;
    z-index: 3;
}
.please-wait .looping-circles .sp#sp1 {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.please-wait .looping-circles .sp#sp2{
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}
.please-wait .looping-circles .sp#sp3{
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
.please-wait .looping-circles .sp#sp4{
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.please-wait .plz-wait-p {
    top: 500px;
}
@keyframes animate-loop {
    0% {
        transform: translateX(-50%) rotate(0deg);
    }
    100% {
        transform: translateX(-50%) rotate(90deg);
    }
}
.please-wait .plz-wait-p {
    display: flex;
    justify-content: center;
    font-size: 20px;
}
.please-wait .plz-wait-p .dot {
    animation-name: animate-dots;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function:ease-in-out;
    margin-left: 8px;
    animation-duration: 1s;
}
.please-wait .plz-wait-p .dot.dot2 {
    animation-delay: 0.33334s;
}
.please-wait .plz-wait-p .dot.dot3 {
    animation-delay: 0.6667s;
}
@keyframes animate-dots {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100;
    }
}
.wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(1.3);
    top: 370px;
}
.checkmark__circle{
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 4;
    stroke-miterlimit: 10;
    stroke: #CDA34F;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}
.checkmark{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #CDA34F;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}
.checkmark__check{
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}
@keyframes stroke{
    100%{stroke-dashoffset: 0}
}@keyframes scale{
    0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}
}@keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px #CDA34F}
}
@media (min-width :767px) {
    .please-wait p {
        font-size: 40px;
    }
    .please-wait .looping-circles {
        transform: scale(1.5) translateX(-50%);
        animation-name:animate-loop2 ;
        margin-top: 50px;
    }
    .please-wait .plz-wait-p {
        /* transform: scale(1.5) translateX(-50%); */
        font-size: 30px;
        margin-top: 80px;
    }
    .checkmark {
        scale: 1.5;
        margin-top: 100px;
    }
}
@keyframes animate-loop2 {
    0% {
        transform: translateX(-50%) rotate(0deg) scale(1.5);
    }
    100% {
        transform: translateX(-50%) rotate(90deg) scale(1.5);
    }
}