html {
  scroll-behavior: smooth;
}
body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    user-select: none;
}
/* width */
::-webkit-scrollbar {
    display: none;
    /* width: 10px; */
}

  /* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

  /* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: var(--vf, linear-gradient(180deg, #CDA34F 0%, #A77C27 100%));
}

  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--vf, linear-gradient(180deg, #CDA34F 50%, #A77C27 100%));
}
a {
  text-decoration: none;
}
.animation-button {
  font-family: Poppins;
  left: -164px;
  border-radius: 5px 20px 20px 5px;
  font-size: 12px;
  text-align: left;
  padding: 0;
  padding-left: 20px;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}
@keyframes sand-show {
  0% {
    left: -164px;
  }
  5%{
    left: -4px;
  }
  95% {
    left: -4px;
  }
  100% {
    left: -164px;
  }
}
.offcanvas  {
  width: 100vw !important;
  background-image: url(./imgs/goodbye/goodbyeBackGround.svg);
  background-size: cover;
  color: white;
  z-index: 9999999;
  height: 100vh !important;
}
.offcanvas .btn-close {
  background-color: white;
  opacity: 80%;
  position: absolute;
  top: 35px;
  right: 30px;
}
.offcanvas .btn-close:hover {
  opacity: 90%;
}
.offcanvas-title {
  text-align: center;
  width: 100%;
  margin-top: 100px;
  font-size: 30px;
  font-weight: 900;
  background: var(--mki, linear-gradient(180deg, #CA9D4A 0%, #E0DA97 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.offcanvas-body {
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 35px;
  font-size: 19px;
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 34.28%, #000 200.34%);
}
.meal-tracking {
  /* padding-top: 30px; */
  position: fixed;
  bottom: 0%;
}
.meal-tracking svg {
  max-width: 100vw;
}
.meal-tracking svg g g g path[d = " M800,-600 C800,-600 800,600 800,600 C800,600 -800,600 -800,600 C-800,600 -800,-600 -800,-600 C-800,-600 800,-600 800,-600z"] {
  fill: transparent;
}
.meal-tracking svg g[transform = "matrix(1,0,0,1,0,0)"] path[fill = "rgb(107,53,45)"] {
  fill: #CA9D4A;
}
.meal-tracking svg g[transform = "matrix(1,0,0,1,0,0)"] path[fill = "rgb(41,23,23)"] {
  fill: black;
}
.ar a {
  font-family: 'Tajawal', sans-serif !important;
}
.ar h1 , .ar h2 , .ar h3 , .ar h4 , .ar h5 , .ar h6 {
  font-family: 'Tajawal', sans-serif !important;
}
.ar p {
  font-family: 'Tajawal', sans-serif !important ;
}
.ar span{
  font-family: 'Tajawal', sans-serif !important ;
}
.ar div {
  font-family: 'Tajawal', sans-serif !important ;
}
.ar .card-home {
  direction: rtl;
}
.ar .card-home h2 {
  padding-right: 20px;
}
.ar .rest-info span{
  font-family: Poppins !important;
}
.ar .about-container h2{
  font-family: Great Vibes !important;
} 
@media(min-width : 767px) {
  .offcanvas-title {
    transform: scale(1.5);
  }
  .offcanvas .btn-close {
    transform: scale(1.6);
    top: 55px;
    right: 66px;
  }
  .spinner-grow {
    transform: scale(2.4);
  }
  .text-tracking {
    font-size: 35px;
  }
  .pop-up-200 {
    transform: translateX(-50%) scale(1.6) !important;
  }
  .pop-up-200.up {
    transform: translateX(-50%) translateY(-250%) !important;
    transition-duration:0.7s ;
}
  .cash-list {
    transform: scale(1.6);
    width: 400px !important;
  }
}