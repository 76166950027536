.nav-container {
    background-color: #000;
    width: 100%;
    position: fixed;
    z-index: 100;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 25px 15px;
}
.nav-container ul {
    width: 120px;
    padding: 0;
    margin: 0;
    color: white;
    list-style: none;
    display: flex;
    justify-content: space-around;
}
.nav-container ul li {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}
.mr-35 {
    margin-right: 20px;
}

@media(max-width:500px) {
    .mr-35 {
        margin-right: 20px;
    }
}
@media(max-width:360px) {
    .mr-35 {
        margin-right: 15px;
    }
}
@media(max-width:330px) {
    .mr-35 {
        margin-right: 20px;
    }
}
@media(max-width:300px) {
    .mr-35 {
        margin-right: 15px;
    }
}
@media(max-width:290px) {
    .mr-35 {
        margin-right: 7px;
    }
}
.nav-container ul li span {
    display: block;
    padding-top: 7px;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.49%;
    max-width: 100%;
    text-align: center;
} 

.circle-icon1 {
    width: 80px;
    background-color: transparent;
}
.circle-icon2{
    z-index: 100;
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: linear-gradient(180deg, #CA9D4A 0%, #E0DA97 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle-icon2.active-circle::after {
    position: absolute;
    z-index: 6;
    content: "\2715";
    width: 100%;
    height: 100%;
    border-radius: 51%;
    background: linear-gradient(to bottom, white, #A6A6A6);
    top: 0;
    left: 0;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: bold;
}
.circle-icon2 span {
    display: block;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.49%;       
}
.icon-nav {
    position: relative;
    width: 100%;
    height: 16px;
}
.icon-nav svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.icon-nav .onhover {
    opacity: 0;
    transition-duration: 0.3s;
}
.icon-nav .normal {
    opacity: 100;
    transition-duration: 0.3s;
}
.nav-container ul .icon-nav .meal {
    position: relative;
}
.nav-container ul .icon-nav .meal svg:first-child {
    position: absolute;
    top: 0;
}
.nav-container ul .icon-nav .meal svg:last-child {
    position: absolute;
    bottom: -18px;
}
.nav-container ul li span {
    color: white;
}
.list li:hover .icon-nav .onhover {
    opacity: 100;
    transition-duration: 0.3s;
}
.list li:hover .icon-nav .normal {
    opacity: 0;
    transition-duration: 0.3s;
}
.list li:hover span {
    background: var(--mki, linear-gradient(180deg, #CA9D4A 0%, #E0DA97 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.list li a.active-nav .icon-nav .onhover {
    opacity: 100;
    transition-duration: 0.3s;
}
.list li a.active-nav .icon-nav .normal {
    opacity: 0;
    transition-duration: 0.3s;
}
.list li a.active-nav span {
    background: var(--mki, linear-gradient(180deg, #CA9D4A 0%, #E0DA97 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.left-side{
    z-index: -1;
    position: absolute;
    left: 0px;
    bottom: -100px;
    opacity: 0;
    transition-duration: .1s;
    transition-delay: .2s;

}
.left-side-active{
    z-index: 2;
    position: absolute;
    left: -62px;
    bottom: -19px;
    opacity: 100;
    transition-duration: .1s;
    transition-delay: .5s;
}
.left-side p {
    position: absolute;
    color: #FFF;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 114.99%;
    z-index: 2;
    top: 50%;
    left: -5px;
    transform: translateY(-50%) rotate(-90deg);
}
.top-side{
    z-index: -1;
    position: absolute;
    top: 100px;
    left: -27px;
    opacity: 0;
    transition-duration: .1s;
    transition-delay: .3s;
}
.top-side-active{
    z-index: 2;
    position: absolute;
    top: -65px;
    left: -26.5px;
    opacity: 100;
    transition-duration: .1s;
    transition-delay: .3s;
}
.top-side p {
    position: absolute;
    color: white;
    width: 50%;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 114.99%;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-60%);
}
.right-side{
    z-index: -1;
    position: absolute;
    top: 100px;
    right: 0px;
    opacity: 0;
    transition-duration: .1s;
}
.right-side-active{
    z-index: 2;
    position: absolute;
    top: -35px;
    right: -64px;
    opacity: 100;
    transition-duration: .1s;
    transition-delay: 0s;
}
.right-side p{
    position: absolute;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    z-index: 2;
    top: 60%;
    right: -2px;
    width: 100%;
    transform: translateY(-50%) rotate(90deg);
}
.pop-up-200 {
    position: fixed;
    z-index: 1000;
    top: 102px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    border-radius: 24px 0px 24px 24px;
    width: 345px;
    height: 107px;
    transition-duration:0.7s ;
}
.pop-up-200.up {
    transform: translateX(-50%) translateY(-550%);
    transition-duration:0.7s ;
}
.pop-up-200 svg {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
}
.pop-up-200 p {
    width: 200px;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
}
.pay-method {
    position: fixed;
    z-index: 999999999;
    background: rgba(0, 0, 0, 0.31);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pay-method .cash-list {
    width: 345px;
    height: 300px;
    border-radius: 40px;
    background: #FFF;
    position: relative;
}
.pay-method .cash-list h3{
    background: var(--vf, linear-gradient(180deg, #CDA34F 0%, #A77C27 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.49%;   
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 32px;
}
.pay-method .cash-list p {
    width: 279px;
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 73px;
}
.pay-method .cash-list .method-list {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 115px;
    list-style: none;
    margin: 0;
    padding: 0;
}
.pay-method .cash-list .method-list li {
    width: 240px;
    height: 52px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.50);
    display: flex;
    justify-content: start;
    align-items: center;
    text-transform: capitalize;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.49%;
    position: relative;
    margin-top: 10px;
    cursor: pointer;
}
.pay-method .cash-list .method-list-1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 115px;
    list-style: none;
    margin: 0;
    padding: 0;
}   
.pay-method .cash-list .method-list-1 li {
    width: 240px;
    height: 52px;
    border-radius: 12px;
    background: var(--vf, linear-gradient(180deg, #CDA34F 0%, #A77C27 100%));
    color: white;
    border: 1px solid #a77c2732;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.49%;
    position: relative;
    margin-top: 10px;
    cursor: pointer;
}
.pay-method .cash-list .method-list-1 li:hover {
    background: var(--vf, linear-gradient(180deg, #be994d 0%, #9e731e 100%));
}
.pay-method .cash-list .method-list li.active-cash {
    color: #CA9D4A;
}
.pay-method .cash-list .method-list li span {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 1px solid black;
    margin: 0 24px;
    position: relative;
}
.pay-method .cash-list .method-list li.active-cash span {
    border: 1px solid #CA9D4A;
}
.pay-method .cash-list .method-list li span::after{
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    background-color:#A77C27 ;
    top: 2.5px;
    left: 3px;
    border-radius: 50%;
    opacity: 0;
    transition-duration: .4s;
}
.pay-method .cash-list .method-list li span:nth-child(1)::after {
    /* top: 1.68px; */
}
.pay-method .cash-list .method-list li.active-cash span::after {
    opacity: 100;
    transition-duration: .4s;
}
.pay-method .cash-list .cash-btns {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 21px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pay-method .cash-list .cash-btns .cancel {
    border: none;
    background-color: transparent;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 128%;
    margin-right: 10px;
}
.pay-method .cash-list .cash-btns .submit {
    border-radius: 80px;
    background: #CDA34F;  
    color: white;  
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 128%;
    padding: 12px 20px;
    border: none;
    width: 100px;
    height: 45px;
}
@media(min-width : 767px) {
    .nav-container {
        height: 123.878px;
    }
    .circle-icon2 {
        width: 117px;
        height: 117px;
        bottom: 60px;
    } 
    .circle-icon2 span {
        color: #000;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 140.49%; /* 30.908px */
    }
    .nav-container ul li {
        transform: scale(1.9);
    }
    .nav-container .list {
        margin: 0 0px;
        width: 35%;
    }
    .left-side {
        transform: scale(1.5);
    }
    .left-side p , .right-side p , .top-side p  {
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 114.99%;
    }
    .right-side p {
        top: 50%;
    }
    .circle-icon2.active-circle::after {
        font-size: 38px;
        font-weight: 900;
    }
    .left-side-active{
        left: -73px;
        bottom: 2px;
    }
    .right-side {
        transform: scale(1.5);
    }
    .right-side-active {
        top: -19px;
        right: -75px;
    }
    .top-side {
        transform: scale(1.5);
    }
    .top-side-active {
        top: -80px;
        left: -9px;
    }
}
