.page{
    /* background-color: black; */
    height: 100%;
}
h2 {
    margin: 0;
}
.home {
    margin-top: 102px ;
    background-image: url(../../imgs/home/homeBG.png);
    background-color: #000;
    background-size: cover;
    background-position: center bottom;
    min-height: 100vh;
    padding-bottom: 120px;
}
.card-home .imgs-container {
    height: 155px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.card-home .imgs-container .left-s {
    z-index: 1;
    left: 0;
    top: 0;
}
.card-home .imgs-container .right-s {
    z-index: 1;
    right: 0;
    top: 0;
}
.card-home .imgs-container .meat {
    opacity: 5%;
    width: 100%;
}
.card-home .imgs-container .card-svg {
    left: 50%;
    transform: translateX(-50%);
    top: 23px;
}
.card-home .imgs-container .text-card-home {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 69px;
    transform: translateX(-50%);
    width: 100%;
}
.card-home .imgs-container .text-card-home p {
    color: #000;
    text-align: center;
    font-family: Great Vibes;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
}
.card-home .imgs-container .text-card-home:hover p{
    background: var(--mki, linear-gradient(180deg, #CA9D4A 40%, #E0DA97 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.card-home .imgs-container img {
    position: absolute;
}
.card-home h2 {
    margin: 15px 0 12px 25px;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.palas .text-card-home {
    top: 29px !important;
}
.rect {
    width: 100%;
    opacity: 100%;
    position: absolute;
    height: 155px;
}
.card-home  svg{
    margin-top: -30px !important; 
}
@media(min-width : 767px) {
    .home {
        margin-top: 180px;
    }
    .card-home {
        height: 240px;
    }
    .card-home h2 {
        font-size: 24px;
    }
    .card-home .imgs-container {
        height: 190px;
    }
    .rect {
        height: 190px !important;
    }
    .left-s {
        height: 190px !important;
    }
    .right-s {
        height: 190px !important;
    }
    .card-home .imgs-container .card-svg {
        top: 14px;
        width: 55px !important;
        height: 55px !important;
    }
    .card-home  svg{
        margin-top: -20px;
    }
    .card-home .menu-p {
        margin-top: 60px !important;
    }
    .card-home .imgs-container .text-card-home {
        top: 79px;
    }
    .card-home .imgs-container .text-card-home p   {
        color: #000;
        text-align: center;
        font-family: Great Vibes;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}