.carouselExampleIndicators {
    width: 100%;
    height: 400px;
}
.carousel-inner {
    width: 100vw;
    min-height: 350px;
}
.carousel-item {
    position: relative;
    height: 310px;
}
.carousel-item img {
    height: 100%;
    width: 100%;
}
.carousel-item::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.95%, #000 100%);
}
.carousel-control-next-icon {
    display: none;
}
.carousel-control-prev-icon {
    display: none;
}
.carousel-indicators {
    margin-bottom: 0 !important;
}
.carousel-indicators button {
    width: 5px !important;
    height: 5px !important;
    border-radius: 50%;
    background-color: #FFECC7 !important;
}
.carousel-indicators button.active {
    background-color: #CDA34F !important;
}
.about-container {
    overflow-x: hidden;
    overflow-y: scroll;
    /* padding-right: 10px; */
    background-color: #000;
    /* height: 200vh; */
}
.about-container h2{
    text-align: center;
    font-family: Great Vibes;
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: var(--mki, linear-gradient(180deg, #CA9D4A 0%, #E0DA97 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 23px;
}
.about-container {
    height: 100vh;
    padding-bottom: 110px;
}
.about-container p {
    color: #FFF;
    text-align: justify;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 85%;
    margin: 5px auto;
}
.rest-info {
    margin-top: 26px;
    margin-left: 30px;
    color: white;
}
.rest-info .info {
    color: var(--cwe, #FFF);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    margin: 13px 0 0;
    display: flex;
}
.text-line {
    text-decoration: underline;
}
.rest-info .info span {
    display: block;
}
.rest-info .info svg {
    margin-right: 30px;
}
.clock {
    margin-top: 13px !important;
}
.clock svg{
    transform: translateY(-13px) translateX(-2px);
}
.clock span{
    transform: translateY(0px) translateX(-17px)
}
.location {
    transform: translateY(-10px) translateX(4px);
}
.social {
    margin: 0 auto;
    margin-top: 35px;
    width: 80%;
    transform: translateX(5px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.social img {
    width: 82px;
    height: 82px;
}
@media(min-width : 767px) {
    .about-container h2 {
        font-size: 62px ;
    }
    .rest-info {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        transform: scale(1.3);
        margin: 26px auto;
        justify-content: center;
    }
    .rest-info .info {
        width: 300px;
        margin-top: 30px !important;
    }
    .social {
        transform: scale(1.3);
        width: 60%;
        margin-top: 80px;
    }
    .carousel-item::after {
        margin-bottom: -2px;
    }
}
.carousel-item {}
.carousel-item::after {
    margin-bottom: -2px;
}