.header {
    position: fixed;
    background-color: black;
    top: 0;
    z-index: 101;
    width: 100%;
    height: 102px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header img {
    width: 100px;
    height: 68px;
}
.language {
    cursor: pointer;
    position: absolute;
    right: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.language .selected-lang {
    background: var(--mki, linear-gradient(180deg, #CA9D4A 0%, #E0DA97 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-right: 9px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.icon {
    transition-duration: .5s;
}
.rotate180{
    transform: rotate(180deg);
    transition-duration: .5s;
}
.lang-list {
    height: 160px;
    overflow-y: scroll;
    list-style: none;
    color: white;
    background-color: black;
    z-index: 100;
    position: absolute;
    top: 30px;
    right: -12px;
    border-radius: 8px;
    border: 1px solid rgba(202, 157, 74, 0.60);
    padding: 0;
}
.card-menu.d-none {
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    display: block !important;
} 
.card-menu.d-none .price {
    display: none !important;
} 
.card-menu.d-none .card-text{
    display: none !important;
}
.lang-list li:not(:last-child) {
    border-bottom: 1px solid;
    border-color:  rgba(202, 157, 74, 0.60)    ;
}
.lang-list li {
    padding: 5px 0;
    width: 70px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}
.lang-list li:hover {
    background: var(--mki, linear-gradient(180deg, #CA9D4A 0%, #E0DA97 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@media(min-width :767px) {
    .header {
        height: 180px;
    }
    .header img {
        width: 169px;
        height: 114px;
    }
    .language {
        right: 66px;
        transform: scale(1.6);
    }
}