.img_item_page {
    z-index: 102;
    position: fixed;
    left: 0;
    top: 0;
    /* height: 102px; */
    width: 100%;
}
.arrow-back {
    position: fixed;
    top: 43px;
    left: 35px;
    z-index: 102;
    cursor: pointer;
}
.item_page {
    min-height: calc(100vh - 102px);
    min-width: 100vw;
    background-color: black;
    margin-top: 102px;
    position: relative;
}
.item_page img {
    width: 100%;
    height: 266px;
    position: relative; 
    z-index: 0;   
}
.overlay_image_item {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 1.29%, #000 99.65%);
    width: 100%;
    height: 300px;
    position: absolute;
    top: 50px;
}
.content_item {
    color: white;
    position: relative;
}
.price_item {
    background-color: #CDA34F;
    width: 101px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 0px 40px 40px 0;
    margin-top: -20px;
    position: relative;
}
.content_item .header_item {
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.49%; /* 28.098px */
    width: 90%;
    margin: 0 auto;
    margin-top: 25px;
}
.content_item .desc_item {
    width: 90%;
    margin: 0 auto;
    margin-top: 15px;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
}
.amount2 {
    position: fixed;
    bottom: 80px;
    right: 20px;
}
@media(min-width:767px) {
    .item_page {
        margin-top: 180px !important;
    }
    .item_page img {
        height: 553px !important;
    }
    .overlay_image_item {
        top: 260px !important;
    }
    .price_item {
        transform: scale(1.8);
        left: 40px;
        bottom: 80px;
    }
    .header_item {
        color: #FFF;
        font-family: Poppins;
        font-size: 30px !important;
        font-style: normal;
        font-weight: 700;
        text-align: center;
        line-height: 140.49%; /* 33.718px */
        margin-bottom: 40px !important;
    }
    .desc_item {
        color: #FFF;
        text-align: justify;
        display: flex;
        justify-content: center;
        font-family: Poppins;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
    }
    .item_page .clicker {
        /* bottom: 100px; */
    }
    .item_page .amount2 {
        bottom: 200px;
        transform: scale(1.2);
        right: 60px;
    }
    .item_page .add-note {
    transform: scale(1.8);
    }
}
.item_page .add-note {
    box-shadow: 0 0px 5px 0px black;
}