.menu-container {
    background-color: black;
    min-height: 100vh;
    background-image: url(../../imgs/global/Group\ 1.png);
    background-size: contain;
    background-position: center bottom;
    padding-bottom: 150px;
}
.menu-container .imgs-container {
    margin-top: 102px;
    height: 155px;
    width: 100%;
    position: relative;
}
.menu-container .imgs-container .left-s {
    z-index: 1;
    left: 0;
    top: 0;
}
.menu-container .imgs-container .right-s {
    z-index: 1;
    right: 0;
    top: 0;
}
.menu-container .imgs-container .text-card-home {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 27px;
    transform: translateX(-50%);
    width: 100%;
}
.menu-container .imgs-container .text-card-home p {
    background: var(--ggggg, linear-gradient(180deg, #CDA34F 0%, #A77C27 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: Great Vibes;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
}
.menu-container .imgs-container img {
    position: absolute;
}
.menu-container h2 {
    margin: 15px 0 12px 25px;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}
.rect {
    width: 100%;
    opacity: 100%;
    position: absolute;
    height: 155px;
}
.search {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 25px 18px;
    position: relative;
}
.search input {
    width: 100%;
    height: 36px;
    background-color: transparent;
    border-radius: 20px;
    border: 1px solid white;
    padding: 7px 20px;
    color: white;
}
.search .filter-icon {
    cursor: pointer;
    margin-left: 11px;
    position: relative;
}
.search .filter-icon .filter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-55%);
}
.search .filter .normal-icon {
}
.search .filter-icon .active-icon-search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.opacity-0 {
    opacity: 0;
}
.filter-list {
    padding: 0;
    margin: 0;
    position: absolute;
    right: 22px;
    top: 52px;
    list-style: none;
    background-color: black;
    border-radius: 12px;
    z-index: 100;
}
.filter-list .fil-li {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    color: white;
    padding: 15px 24px 11px 24px;
}
.filter-list .fil-li svg {
    margin-right: 22px;
    cursor: pointer;
}
.filter-list .fil-li:not(:last-child) {
    border-bottom: 1px solid rgba(202, 157, 74, 0.60) ;
}
.card-menu.d-none {
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
    display: block !important;
} 
.card-menu.d-none .price {
    display: none !important;
} 
.card-menu.d-none .card-text{
    display: none !important;
}
.card-menu.d-none .old-price {
    display: none !important;
}
.card-type {
    opacity: 0;
}
.id-pack {
    opacity: 0;
}
.active-icon-filter {
    position: absolute;
}
.scrollable-bar {
    width: 100%;
    overflow: hidden;
    background-color: black;
}
.scroll-container {
    width: 120%;
    display: flex;
    flex-wrap: nowrap;
    color: #FFF;
    padding: 0 20px 20px;
    overflow: auto ;
    white-space: nowrap;
}
.scrollable-bar .scroll-container .item-sc-bar {
    cursor: pointer;
    width: 500px;
    color: #FEFEFE;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-left: 10px;
    padding: 8px 18px;
    border: 1px solid white;
    border-radius: 18px;
    transition-duration: .3s;
}
.scrollable-bar .scroll-container .item-sc-bar:last-child {
    margin-right: 80px;
}
.scrollable-bar .scroll-container .item-sc-bar.active {
    background: var(--mki, linear-gradient(180deg, #CA9D4A 0%, #E0DA97 100%));
    border: none;
    color: black;
    transition-duration: .3s;
}
.search-icon .normal-icon, .search-icon .active-icon {
    position: absolute;
    top: 17px;
    right: 86px;
}
.search-icon .normal-icon  {
    opacity: 100;
}
.search-icon .active-icon {
    opacity: 0;
    cursor: pointer;
}
.search-icon.active-search .normal-icon  {
    opacity: 0;
}
.search-icon.active-search .active-icon {
    opacity: 100;
}
.spinner {
    position: absolute;
    left: calc(50% - 0.8rem);
    top: 40px;
}
.activeFilters {
    position: absolute;
    right: 25px;
    top: 2px;
}
.activeFilters span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-30%) translateY(-50%);
    color: #000;
    font-family: Montserrat;
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}
@media(min-width : 767px) {
        .menu-container .imgs-container {
            margin-top: 180px;
            height: 190px;
        }
        .menu-container .imgs-container .rect {
            height: 190px !important;
        }
        .menu-container .text-card-home p {
            text-align: center;
            font-family: Great Vibes;
            font-size: 54px !important;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .search {
            padding: 22px 100px;
        }
        .search input {
            height: 46px;
            border-radius: 40px;
            border: 2px solid white;
            font-size: 22px;
        }
        .search .search-icon .normal-icon{
            top: 30px;
            right: 173px;
            transform: scale(1.5);
        }
        .filter-icon {
            transform: scale(1.4);
            margin-left: 20px !important;
        }
        .filter-list {
            transform: scale(1.4);
            right: 81px;
            top: 117px;
        }
        .scrollable-bar {
            overflow-x: scroll;
        }
        .scrollable-bar .scroll-container .item-sc-bar  {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
        }
        .scrollable-bar .scroll-container .item-sc-bar:last-child  {
            margin-right: 20px;
        }
}