.error-container {
    width: 100vw;
    height: 100vh;
    background-image: url(../../imgs/goodbye/goodbyeBackGround.svg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.error-container img {
    width: 90%;
}
.error-container p {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
}
.error-container span {
    width: 90%;
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    background: #CDA34F;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.49%;
    cursor: pointer;
    transition-duration: .3s;
}
.error-container span:hover {
    background: #c3973f;
    transform: scale(0.95);
    transition-duration: .3s;
}
@media(min-width : 767px) {
    .error-container img {
        margin-top: -200px;
    }
    .error-container p {
        font-size: 35px;
    }
}