.select-parent {
    height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index:999;
    color: white;
    top: 10px;
    left: 30px;
    border-radius: 50%;
}
@media(min-width :767px) {
  .select-parent {
    left: 70px;
    top: 47px;
    transform: scale(1.6);
  }
}
.custom-select {
    appearance: none; /* Remove default appearance */
    font-size: 16px;
  }
  .the-selected-currency {
    background: linear-gradient(180deg, #CA9D4A 0%, #E0DA97 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .the-list-currencies {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100px;
    border-radius: 16px;
    left: -20px;
  }
  .the-list-currencies .select {
    color: rgb(0, 0, 0,0.58);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4px 0px;
      transition-duration: .3s;
  }
  .the-list-currencies .select:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .the-list-currencies .select:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
    .the-list-currencies .select:hover {
      background-color: rgb(234, 234, 234);
      transition-duration: .3s;
  }
  .the-list-currencies .select:not(:last-child) {
    border-bottom: 1px solid #A77C27;
    width: 100%;
  }
  .info-currencies-overlay {
    position: fixed;
    top : 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.284);
    width: 100vw;
    height: 100vh;
    z-index: 999999;
  }
  .info-currencies {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    width: 80%;
    background-color: white;
    color: black;
    padding: 20px 40px;
    border-radius: 40px;
    width: 345px;
    min-height: 276px;
  }
  .info-currencies .close-btn-info {
    position: absolute;
    right: 20px;
    top: 5px;
    cursor: pointer;
    padding: 10px;
  }
  .info-currencies h3 {
    color: rgba(205, 163, 79, 0.96);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 128%; /* 25.6px */
    padding-top: 35px;
    margin-bottom: 30px;
  }
  .info-currencies p {
    color: rgba(0, 0, 0, 0.30);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-top: 20px;
  }
  .info-currencies span.ok-info {
    width: 111px;
    height: 45px;
    border-radius: 80px;
    background: #CDA34F;
    display: block;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 128%; /* 20.48px */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 0 auto;
    cursor: pointer;
  }
  .info-currencies span.ok-info:hover {
    background-color: #A77C27;
  }
  p.exchange {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin: 10px auto;
    width: 170px;
  }
  span.number {
    color: rgba(205, 163, 79, 0.96);
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  @media(min-width : 767px) {
    .info-currencies {
      transform: translateX(-50%) translateY(-50%) scale(1.6);
      width: 400px;
    }
  }